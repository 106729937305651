import React from 'react';
import styled from 'styled-components';
import { theme } from '../../styles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const DashboardContainer = styled.div`
  padding: ${theme.common.spacing.lg};
  padding-top: 8rem;
  background: transparent;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  margin-bottom: ${theme.common.spacing.xl};
  padding-bottom: ${theme.common.spacing.md};
  border-bottom: 1px solid ${theme.colors.border.light};
`;

const Title = styled.h1`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.sm};
  font-size: 1.8rem;
`;

const GrossProfit = styled.div`
  color: ${props => props.negative ? theme.colors.status.error : theme.colors.status.success};
  font-size: 2rem;
  font-weight: bold;
  font-family: ${theme.fonts.body};
`;

const Overview = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 8px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
`;

const SummaryTable = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 8px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: ${theme.common.spacing.sm} ${theme.common.spacing.md};
  border-bottom: 1px solid ${theme.colors.border.light};
  transition: background ${theme.common.transitions.fast};
  
  &:hover {
    background: ${theme.colors.background.muted};
  }
`;

const CategoryLabel = styled.span`
  color: ${theme.colors.text.primary};
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  font-family: ${theme.fonts.body};
  display: flex;
  align-items: center;
`;

const Amount = styled.span`
  color: ${props => props.negative ? theme.colors.status.error : theme.colors.text.primary};
  text-align: right;
  font-family: ${theme.fonts.body};
`;

const SectionTitle = styled.h2`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.lg};
  font-size: 1.2rem;
`;

// Dummy data with theme-based colors and gradients
const dummyData = {
  grossProfit: -30616.5,
  categories: [
    { name: 'Harvesting', amount: 45453.1, year: 2024, color: theme.colors.status.success },
    { name: 'Funding', amount: 7040.0, year: 2024, color: theme.colors.primary },
    { name: 'Road Construction', amount: -16200.0, year: 2024, color: theme.colors.secondary },
    { name: 'Silviculture', amount: -26200.0, year: 2024, color: theme.colors.primaryLight },
    { name: 'Planting', amount: -3564.0, year: 2024, color: theme.colors.primaryDark },
    { name: 'Management', amount: -37145.6, year: 2024, color: theme.colors.text.muted }
  ]
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
        background: theme.common.glassmorphism.background,
        backdropFilter: theme.common.glassmorphism.backdropFilter,
        boxShadow: theme.common.glassmorphism.boxShadow,
        padding: theme.common.spacing.md,
        borderRadius: '4px',
        fontFamily: theme.fonts.body
      }}>
        <p style={{ 
          color: theme.colors.text.dark, 
          margin: '0 0 4px 0',
          fontWeight: 'bold' 
        }}>
          {label}
        </p>
        <p style={{ 
          color: payload[0].value < 0 ? theme.colors.status.error : theme.colors.status.success,
          margin: 0,
          fontSize: '1.1em'
        }}>
          {payload[0].value < 0 ? '-' : ''}CAD ${Math.abs(payload[0].value).toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ManagementPlanView = () => {
  return (
    <DashboardContainer>
      <Header>
        <Title>Management Plan | Climate Forest Canada</Title>
        <GrossProfit negative={dummyData.grossProfit < 0}>
          CAD ${dummyData.grossProfit.toLocaleString()}
        </GrossProfit>
      </Header>

      <Overview>
        <SectionTitle>Overview</SectionTitle>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <BarChart 
              data={dummyData.categories} 
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
                stroke={theme.colors.border.light}
                vertical={false}
              />
              <XAxis 
                dataKey="name" 
                stroke={theme.colors.text.primary}
                tick={{ fill: theme.colors.text.primary, fontSize: 12 }}
                tickLine={{ stroke: theme.colors.border.light }}
              />
              <YAxis 
                stroke={theme.colors.text.primary}
                tick={{ fill: theme.colors.text.primary, fontSize: 12 }}
                tickLine={{ stroke: theme.colors.border.light }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar 
                dataKey="amount"
                radius={[4, 4, 0, 0]}
              >
                {dummyData.categories.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Overview>

      <SummaryTable>
        <SectionTitle>Summary</SectionTitle>
        <TableRow>
          <CategoryLabel bold>Category, Year</CategoryLabel>
          <CategoryLabel bold>2024</CategoryLabel>
          <CategoryLabel bold>Sum</CategoryLabel>
        </TableRow>
        {dummyData.categories.map((category, index) => (
          <TableRow key={index}>
            <CategoryLabel>
              <div style={{ 
                width: '8px', 
                height: '8px', 
                borderRadius: '2px', 
                background: category.color,
                marginRight: theme.common.spacing.sm
              }}/>
              {category.name}
            </CategoryLabel>
            <Amount negative={category.amount < 0}>
              {category.amount < 0 ? '-' : ''}CAD ${Math.abs(category.amount).toLocaleString()}
            </Amount>
            <Amount negative={category.amount < 0}>
              {category.amount < 0 ? '-' : ''}CAD ${Math.abs(category.amount).toLocaleString()}
            </Amount>
          </TableRow>
        ))}
        <TableRow>
          <CategoryLabel bold>Sum</CategoryLabel>
          <Amount negative={dummyData.grossProfit < 0}>
            {dummyData.grossProfit < 0 ? '-' : ''}CAD ${Math.abs(dummyData.grossProfit).toLocaleString()}
          </Amount>
          <Amount negative={dummyData.grossProfit < 0}>
            {dummyData.grossProfit < 0 ? '-' : ''}CAD ${Math.abs(dummyData.grossProfit).toLocaleString()}
          </Amount>
        </TableRow>
      </SummaryTable>
    </DashboardContainer>
  );
};

export default ManagementPlanView;
