import React, { useState, useEffect } from 'react';
import { fetchAndParseDueDiligenceData } from '../services/notionService';
import { Link } from 'react-router-dom';

const NOTION_DATABASE_ID = '550971b2b3354d3da9d59a9daf9b5a96';

/**
 * DueDiligenceGallery component
 * Displays raw due diligence data fetched from Notion
 */
const DueDiligenceGallery = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [parsedData, setParsedData] = useState([]);

  useEffect(() => {
    const fetchDueDiligences = async () => {
      try {
        console.log('Fetching due diligences from Notion API...');
        const parsedData = await fetchAndParseDueDiligenceData(NOTION_DATABASE_ID);
        
        const publicData = parsedData.filter(item => item.viewability === "Public");
        setParsedData(publicData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching due diligences:', err);
        setError(`Failed to fetch due diligences: ${err.message}`);
        setLoading(false);
      }
    };

    fetchDueDiligences();
  }, []);

  if (loading) return <div className="text-center mt-8">Loading due diligences...</div>;
  if (error) return <div className="text-center mt-8 text-red-600">Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Opportunities Gallery</h1>
      
      {/* Parsed Data Section */}
      <div className="mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {parsedData.map(item => (
            <Link 
              to={`/due-diligence/${item.id}`} 
              key={item.id} 
              className="p-4 border rounded shadow hover:shadow-lg transition-shadow"
            >
              <h3 className="font-bold">{item.name}</h3>
              <p className="text-gray-600 text-sm">ID: {item.id}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DueDiligenceGallery;