import React from 'react';

const LoadingOverlay = ({ 
  message = "Loading...",
  position = "fixed",
  background = "rgba(255, 255, 255, 0.9)",
  style = {}
}) => {
  return (
    <div style={{
      position: position,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: background,
      padding: '16px 24px',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 20,
      ...style
    }}>
      {message}
    </div>
  );
};

export default LoadingOverlay; 