import React from 'react';
import { FaDollarSign} from 'react-icons/fa';
import FoldableSection from '../FoldableSection';
import AttributeItem from '../AttributeItem';
import CommentBlock from '../CommentBlock';

const ContiguityValueAdd = ({ data }) => {
  // Helper function to format numbers
  const formatNumber = (num) => {
    return num != null && !isNaN(num) ? num.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 'N/A';
  };

  const attributes = [
    { 
      label: "Value Add", 
      value: formatNumber(data.contiguityValueAdd), 
      icon: FaDollarSign, 
      unit: "CAD",
      color: 'text-green-600',
      borderColor: 'border-green-600'
    },
    { 
      label: "Total (CAD)", 
      value: formatNumber(data.totalContiguityValueAdd), 
      icon: FaDollarSign, 
      unit: "CAD",
      color: 'text-blue-600',
      borderColor: 'border-blue-600'
    },
  ];

  return (
    <FoldableSection title="Contiguity Value Add">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {attributes.map((attribute, index) => (
          <AttributeItem key={index} {...attribute} />
        ))}
      </div>
      <CommentBlock content={data.commentsContiguityValueAdd} />
    </FoldableSection>
  );
};

export default ContiguityValueAdd;