import React, { useState, useEffect } from 'react';

const Particle = ({ x, y, rotation, type, style }) => {
  const particles = {
    snow: '❄',
    leaf: '🍂',
    flower: '🌸'
  };

  return (
    <div 
      style={{ 
        position: 'fixed',
        left: `${x}%`,
        top: y,
        transform: `translateX(-50%) rotate(${rotation}deg)`,
        color: 'white',
        textShadow: '0 0 2px black',
        zIndex: 50,
        fontSize: '1.5rem',
        opacity: style.opacity ?? 0.7,
        transition: type === 'flower' ? 'opacity 0.8s ease-in-out' : 'none',
        ...style
      }}
    >
      {particles[type]}
    </div>
  );
};

const createParticle = (type, index = 0, total = 1) => {
  const baseConfig = {
    x: Math.random() * 100,
    rotation: Math.random() * 360,
  };

  switch (type) {
    case 'winter':
    case 'autumn': {
      // Distribute particles evenly across screen height initially
      const distributedY = -50 + (-window.innerHeight * (index / total));
      return {
        ...baseConfig,
        y: distributedY,
        currentSpeed: type === 'winter' ? 1.2 : 1.8,
      };
    }
    case 'spring': {
      // Distribute flowers across the visible area
      const section = window.innerHeight / total;
      const distributedY = 100 + (section * index) + (Math.random() * 20 - 10);
      return {
        ...baseConfig,
        y: distributedY,
        opacity: Math.random() > 0.5 ? 0.8 : 0.2, // More contrast between states
      };
    }
    default:
      return null;
  }
};

const SeasonalAnimation = ({ season = 'winter' }) => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    // Clear existing animation
    setPositions([]);

    // Create new particles for the current season
    const particleCount = season === 'winter' ? 25 : season === 'autumn' ? 20 : season === 'spring' ? 15 : 0;
    const newParticles = Array.from(
      { length: particleCount }, 
      (_, index) => createParticle(season, index, particleCount)
    );
    setPositions(newParticles);

    let animationId;
    let intervalId;

    if (season === 'winter' || season === 'autumn') {
      const animate = () => {
        setPositions(currentPositions => {
          return currentPositions.map(particle => {
            if (particle.y > window.innerHeight) {
              // Reset particle to top
              return {
                ...particle,
                y: -50,
                x: Math.random() * 100
              };
            }
            return {
              ...particle,
              y: particle.y + particle.currentSpeed
            };
          });
        });
        animationId = requestAnimationFrame(animate);
      };
      animationId = requestAnimationFrame(animate);
    }

    if (season === 'spring') {
      // Randomize which flowers fade at each interval
      intervalId = setInterval(() => {
        setPositions(currentPositions => {
          return currentPositions.map(particle => ({
            ...particle,
            opacity: Math.random() > 0.6 ? 0.8 : 0.2 // 40% chance to be visible
          }));
        });
      }, 800); // Much faster fade interval
    }

    // Cleanup function
    return () => {
      if (animationId) {
        cancelAnimationFrame(animationId);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
      setPositions([]); // Clear particles on cleanup
    };
  }, [season]);

  if (season === 'summer' || positions.length === 0) return null;

  const styles = {
    winter: { color: 'white' },
    autumn: { color: '#D35400', fontSize: '2rem' },
    spring: { color: '#FF69B4', fontSize: '1.3rem' }
  };

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none">
      {positions.map((particle, i) => (
        <Particle 
          key={`${season}-${i}-${particle.y}`}
          x={particle.x}
          y={particle.y}
          rotation={particle.rotation}
          type={season === 'winter' ? 'snow' : season === 'autumn' ? 'leaf' : 'flower'}
          style={{
            ...styles[season],
            opacity: particle.opacity
          }}
        />
      ))}
    </div>
  );
};

export default SeasonalAnimation; 