import React, { useState, useEffect, useRef } from 'react';
import TimelineItem from './TimelineItem';
import TimelineScrollbar from './TimelineScrollbar';
import SeasonalAnimation from './SeasonalAnimation';
import LoadingOverlay from '../BaseComponents/LoadingOverlay';
import { fetchAndParseAirtableRecords } from '../../services/airtableService';
import { theme } from '../../styles';

const portfolioUpdateBaseId = "appOzTtsysmPCXcnP"
const portfolioUpdateTableId = "tbl1Hekyfan3HYO3M"

const styles = `
  .timeline-container {
    scroll-behavior: smooth;
  }
  
  .timeline-container::-webkit-scrollbar {
    width: 0px;
  }

  .timeline-line {
    position: absolute;
    left: 50%;
    width: ${theme.common.timeline.line.width};
    background: ${theme.common.gradients.timeline};
    transform: translateX(-50%);
    z-index: ${theme.common.timeline.line.zIndex};
    top: ${theme.common.timeline.container.topSpacing};
    bottom: 0;
  }

  .timeline-marker {
    position: absolute;
    left: 50%;
    width: ${theme.common.timeline.marker.size};
    height: ${theme.common.timeline.marker.size};
    background: ${theme.common.timeline.marker.background};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: ${theme.common.timeline.marker.zIndex};
    box-shadow: 0 0 0 ${theme.common.timeline.marker.shadowSize} ${theme.common.timeline.marker.shadowColor};
    top: ${theme.common.timeline.container.topSpacing};
  }

  .subtle-gradient {
    background: ${theme.common.gradients.subtle};
  }
`;

export default function TimelineView({ searchTerm }) {
  const [timelineData, setTimelineData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSeason, setCurrentSeason] = useState(null);
  const [linePosition, setLinePosition] = useState({ top: 0, height: 0 });
  const firstItemRef = useRef(null);
  const containerRef = useRef(null);

  const fetchData = async (searchQuery = '') => {
    setIsLoading(true);
    setError(null);
    
    try {
      let filterFormula = 'Published = 1';
      
      if (searchQuery) {
        filterFormula = `AND(Published = 1, FIND("${searchQuery}", Portfolio))`;
      }

      const records = await fetchAndParseAirtableRecords(
        portfolioUpdateBaseId, 
        portfolioUpdateTableId,
        filterFormula
      );
      
      const formattedData = records
        .map(record => ({
          date: record.Date,
          title: record['Update Title'],
          text: record['Update Text'],
          image: record.Images?.[0]?.url || null,
          type: record.Type,
          createdBy: record['Created By'],
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      setTimelineData(formattedData);
    } catch (err) {
      console.error('Error fetching timeline data:', err);
      setError('Failed to load timeline data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (timelineData[currentIndex]) {
      const date = new Date(timelineData[currentIndex].date);
      const month = date.getMonth();
      
      // Determine season based on month
      if (month >= 2 && month <= 4) setCurrentSeason('spring');
      else if (month >= 5 && month <= 7) setCurrentSeason('summer');
      else if (month >= 8 && month <= 10) setCurrentSeason('autumn');
      else setCurrentSeason('winter');
    }
  }, [currentIndex, timelineData]);

  useEffect(() => {
    const updatePositions = () => {
      if (firstItemRef.current && containerRef.current) {
        const firstItemRect = firstItemRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const markerTop = firstItemRect.top - containerRect.top;
        setLinePosition({
          markerTop
        });
      }
    };

    // Initial update with a small delay to ensure DOM is ready
    const initialTimer = setTimeout(updatePositions, 100);
    
    // Update on mount
    updatePositions();
    
    window.addEventListener('scroll', updatePositions);
    window.addEventListener('resize', updatePositions);

    return () => {
      window.removeEventListener('scroll', updatePositions);
      window.removeEventListener('resize', updatePositions);
      clearTimeout(initialTimer);
    };
  }, []);

  const handleMarkerClick = (index) => {
    setCurrentIndex(index);
    const element = document.getElementById(`timeline-item-${index}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <>
      <style>{styles}</style>
      <div 
        ref={containerRef}
        className="relative min-h-screen subtle-gradient"
      >
        <div 
          className="absolute inset-0 transition-opacity duration-200" 
          style={{ 
            opacity: isLoading ? 1 : 0,
            visibility: isLoading ? 'visible' : 'hidden',
            transition: theme.common.transitions.default 
          }}
        >
          <LoadingOverlay message="Loading..." />
        </div>
        {error && (
          <div className="text-red-500 text-center p-4 bg-red-100 rounded-md mx-auto max-w-2xl mt-4">
            {error}
          </div>
        )}
        <div 
          style={{ 
            opacity: isLoading ? 0 : 1,
            transition: theme.common.transitions.default 
          }}
        >
          <SeasonalAnimation season={currentSeason} />
          <TimelineScrollbar 
            currentIndex={currentIndex} 
            timelineData={timelineData}
            onMarkerClick={handleMarkerClick}
          />
          
          <div className="timeline-line" />
          <div 
            className="timeline-marker"
            style={{ 
              top: `${linePosition.markerTop}px`
            }}
          />
          
          <div className="timeline-container px-4 md:px-12 pt-32 pb-8" style={{
            padding: `0 ${theme.common.timeline.container.padding.base} 0 ${theme.common.timeline.container.padding.base}`,
            paddingTop: theme.common.timeline.container.topSpacing,
            paddingBottom: theme.common.timeline.container.bottomSpacing,
            '@media (min-width: 768px)': {
              padding: `0 ${theme.common.timeline.container.padding.md} 0 ${theme.common.timeline.container.padding.md}`
            }
          }}>
            <div className="max-w-7xl mx-auto space-y-8">
              {timelineData.map((item, index) => (
                <div 
                  key={index}
                  id={`timeline-item-${index}`}
                  ref={index === 0 ? firstItemRef : null}
                  className="scroll-mt-32"
                >
                  <TimelineItem
                    index={index}
                    item={item}
                    onInView={() => setCurrentIndex(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 