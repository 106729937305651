export const theme = {
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Merriweather', serif"
  },
  colors: {
    primary: '#7C3AED',
    primaryLight: '#9F69FF',
    primaryDark: '#5B21B6',
    secondary: '#06B6D4',
    text: {
      primary: '#666',
      light: 'white',
      error: '#dc2626',
      muted: '#9CA3AF',
      dark: '#1F2937'
    },
    background: {
      light: '#FFFFFF',
      subtle: '#F9FAFB',
      muted: '#F3F4F6'
    },
    border: {
      light: '#E5E7EB',
      default: '#D1D5DB'
    },
    status: {
      success: '#10B981',
      warning: '#F59E0B',
      error: '#EF4444'
    }
  },
  sizes: {
    small: {
      padding: '4px',
      buttonPadding: '6px 16px',
      fontSize: '14px',
      iconSize: '14px',
      height: '32px'
    },
    medium: {
      padding: '6px',
      buttonPadding: '8px 20px',
      fontSize: '15px',
      iconSize: '15px',
      height: '40px'
    },
    large: {
      padding: '8px',
      buttonPadding: '10px 24px',
      fontSize: '16px',
      iconSize: '16px',
      height: '48px'
    }
  },
  common: {
    glassmorphism: {
      background: 'rgba(255, 255, 255, 0.95)',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.3)',
      backdropFilter: 'blur(5px)'
    },
    gradients: {
      subtle: 'linear-gradient(135deg, rgb(252, 252, 252) 0%, rgb(250, 250, 250) 100%)',
      timeline: 'linear-gradient(180deg, rgba(59, 130, 246, 0.5), rgba(34, 197, 94, 0.5))'
    },
    borderRadius: '50px',
    shadows: {
      sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
      md: '0 4px 6px rgba(0, 0, 0, 0.1)',
      lg: '0 10px 15px rgba(0, 0, 0, 0.1)'
    },
    transitions: {
      fast: '150ms ease-in-out',
      default: '200ms ease-in-out',
      slow: '300ms ease-in-out'
    },
    spacing: {
      xs: '4px',
      sm: '8px',
      md: '16px',
      lg: '24px',
      xl: '32px'
    },
    timeline: {
      line: {
        width: '2px',
        zIndex: 0
      },
      marker: {
        size: '20px',
        background: 'rgb(59, 130, 246)',
        shadowColor: 'rgba(59, 130, 246, 0.2)',
        shadowSize: '4px',
        zIndex: 1
      },
      container: {
        padding: {
          base: '1rem',
          md: '3rem'
        },
        topSpacing: '8rem',
        bottomSpacing: '2rem'
      }
    }
  },
  animations: {
    spin: {
      name: 'spin',
      duration: '20s',
      timing: 'linear',
      iteration: 'infinite'
    }
  }
}; 