import React from 'react';
import {FaWater, FaRoad, FaTree, FaUmbrellaBeach} from 'react-icons/fa';
import FoldableSection from '../FoldableSection';
import AttributeItem from '../AttributeItem';
import CommentBlock from '../CommentBlock';

const AdditionalDevelopmentValue = ({ data }) => {
  // Helper function to format numbers
  const formatNumber = (num) => {
    return num != null && !isNaN(num) ? num.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 'N/A';
  };

  const attributes = [
    {
      label: "Water Frontage (Lake&River) (CAD)",
      value: formatNumber(data.waterFrontageValue),
      icon: FaWater,
      unit: "CAD"
    },
    {
      label: "Public Road Frontage Development (CAD)",
      value: formatNumber(data.publicRoadFrontageDevelopment),
      icon: FaRoad,
      unit: "CAD"
    },
    {
      label: "Land Base Value (CAD)",
      value: formatNumber(data.landBaseValue),
      icon: FaTree,
      unit: "CAD"
    },
    {
      label: "Recreational Value (CAD)",
      value: formatNumber(data.recreationalValue),
      icon: FaUmbrellaBeach,
      unit: "CAD"
    }
  ];

  return (
    <FoldableSection title="Additional Development / Recreational Value">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        {attributes.map((attribute, index) => (
          <AttributeItem key={index} {...attribute} />
        ))}
      </div>
      <CommentBlock content={data.commentsAdditionalDevelopmentValue} />
    </FoldableSection>
  );
};

export default AdditionalDevelopmentValue;