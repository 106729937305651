import React from 'react';
import { theme } from '../../styles';

const SearchForm = ({ 
  searchTerm, 
  onSearchChange, 
  onSubmit, 
  placeholder = "Search...",
  buttonText = "Search",
  size = 'medium',
  error = null,
  style = {}
}) => {
  const currentSize = theme.sizes[size] || theme.sizes.medium;

  return (
    <div style={{ 
      ...theme.common.glassmorphism,
      borderRadius: theme.common.borderRadius,
      display: 'inline-flex',
      position: 'relative',
      ...style
    }}>
      <form 
        onSubmit={onSubmit} 
        style={{ 
          display: 'flex', 
          gap: '4px',
          padding: currentSize.padding
        }}
      >
        <input
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
          style={{
            padding: '0 16px',
            fontSize: currentSize.fontSize,
            borderRadius: theme.common.borderRadius,
            border: 'none',
            background: 'rgba(255, 255, 255, 0.8)',
            width: '200px',
            height: currentSize.height,
            boxSizing: 'border-box',
            lineHeight: '1',
            display: 'flex',
            alignItems: 'center',
          }}
        />
        <button
          type="submit"
          style={{
            padding: '0 16px',
            fontSize: currentSize.fontSize,
            borderRadius: theme.common.borderRadius,
            border: 'none',
            background: theme.colors.primary,
            color: theme.colors.text.light,
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            height: currentSize.height,
            boxSizing: 'border-box',
            lineHeight: '1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          {buttonText}
        </button>
      </form>
      {error && (
        <div style={{ 
          color: theme.colors.text.error, 
          fontSize: currentSize.fontSize,
          marginTop: '8px',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '4px 8px',
          borderRadius: theme.common.borderRadius,
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
        }}>
          {error}
        </div>
      )}
    </div>
  );
};

export default SearchForm; 