import React from 'react';

const TimelineScrollbar = ({ currentIndex, timelineData, onMarkerClick }) => {
  return (
    <div className="fixed left-4 top-1/2 -translate-y-1/2 z-50">
      <div className="flex flex-col gap-4">
        {timelineData.map((item, index) => (
          <button
            key={index}
            onClick={() => onMarkerClick(index)}
            className={`w-auto h-auto px-2 py-1 rounded-full text-xs transition-all duration-200
              ${currentIndex === index 
                ? 'bg-green-500 text-white hover:scale-150' 
                : 'bg-green-200 text-gray-700 hover:bg-green-300 hover:scale-150'}`}
            aria-label={`Go to item from ${item.date}`}
          >
            {new Date(item.date).toLocaleDateString(undefined, { month: 'short', year: '2-digit' })}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TimelineScrollbar; 