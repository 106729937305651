import React from 'react';
import { theme } from '../../styles';

/**
 * Button component with modern styling
 * 
 * @param {Object} props - The properties passed to the component
 * @param {string} props.variant - The button variant (primary, secondary)
 * @param {string} props.size - The button size (small, medium, large)
 * @param {React.ReactNode} props.children - The content of the button
 * @param {function} props.onClick - The function to call when the button is clicked
 * @param {string} props.className - Additional CSS classes to apply to the button
 * @returns {React.Element} A styled button component
 */
const Button = ({ variant = 'primary', size = 'medium', children, onClick, className = '', disabled = false }) => {
  const getVariantStyles = () => {
    const variants = {
      primary: {
        backgroundColor: theme.colors.primary,
        color: theme.colors.text.light,
        border: 'none',
      },
      secondary: {
        backgroundColor: 'transparent',
        color: theme.colors.primary,
        border: `2px solid ${theme.colors.primary}`,
      }
    };
    return variants[variant];
  };

  const getSizeStyles = () => {
    return {
      padding: theme.sizes[size].buttonPadding,
      fontSize: theme.sizes[size].fontSize,
      height: theme.sizes[size].height,
    };
  };

  const buttonStyles = {
    ...getVariantStyles(),
    ...getSizeStyles(),
    borderRadius: theme.common.borderRadius,
    transition: 'all 0.3s ease',
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.6 : 1,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '500',
    outline: 'none',
  };

  const hoverStyles = {
    transform: 'translateY(-1px)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    opacity: 0.9,
  };

  return (
    <button
      className={`focus:outline-none ${className}`}
      onClick={onClick}
      disabled={disabled}
      style={buttonStyles}
      onMouseOver={(e) => !disabled && Object.assign(e.currentTarget.style, { ...buttonStyles, ...hoverStyles })}
      onMouseOut={(e) => Object.assign(e.currentTarget.style, buttonStyles)}
    >
      {children}
    </button>
  );
};

export default Button;