import React, { useRef, useEffect, useState } from 'react';
import { theme } from '../../styles';

const SwitchSelector = ({ 
  options = [],
  activeOption,
  onSwitch,
  size = 'medium',
}) => {
  const [activeButtonRect, setActiveButtonRect] = useState(null);
  const buttonsRef = useRef({});
  const currentSize = theme.sizes[size] || theme.sizes.medium;

  useEffect(() => {
    const activeButton = buttonsRef.current[activeOption];
    if (activeButton) {
      const rect = activeButton.getBoundingClientRect();
      const parentRect = activeButton.parentElement.getBoundingClientRect();
      setActiveButtonRect({
        width: rect.width,
        height: rect.height,
        left: rect.left - parentRect.left,
      });
    }
  }, [activeOption]);

  return (
    <div style={{
      ...theme.common.glassmorphism,
      padding: currentSize.padding,
      borderRadius: theme.common.borderRadius,
      display: 'inline-flex',
      gap: '4px',
      position: 'relative',
    }}>
      {activeButtonRect && (
        <div
          style={{
            position: 'absolute',
            width: activeButtonRect.width,
            height: activeButtonRect.height,
            left: activeButtonRect.left,
            borderRadius: theme.common.borderRadius,
            background: theme.colors.primary,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            zIndex: 0,
          }}
        />
      )}
      
      {options.map((option) => (
        <button
          key={option.value}
          ref={el => buttonsRef.current[option.value] = el}
          onClick={() => onSwitch(option.value)}
          style={{
            padding: currentSize.buttonPadding,
            height: currentSize.height,
            borderRadius: theme.common.borderRadius,
            border: 'none',
            background: 'transparent',
            color: activeOption === option.value ? theme.colors.text.light : theme.colors.text.primary,
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '6px',
            fontSize: currentSize.fontSize,
            position: 'relative',
            zIndex: 1,
            boxSizing: 'border-box',
            lineHeight: '1',
          }}
        >
          {React.createElement(option.icon, { 
            style: { 
              fontSize: currentSize.iconSize,
              transition: 'all 0.3s ease',
            }
          })}
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default SwitchSelector; 