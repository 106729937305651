import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Menu from "./Menu";
import Button from "./Button";
import { theme } from "../../styles";

/**
 * Header component for forest-themed web app
 *
 * @returns {React.Element} A styled header component with navigation menu and authentication controls
 */
const Header = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const publicMenuItems = [
    { label: "Opportunities", href: "/due-diligences" },
  ];

  const privateMenuItems = [
    { label: "Portfolio", href: "/portfolio" },
    { label: "Admin", href: "/debug" },
  ];

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const menuItems = isAuthenticated
    ? [...publicMenuItems, ...privateMenuItems]
    : publicMenuItems;

  return (
    <header 
      className="fixed top-0 left-0 right-0 z-50"
      style={{ 
        background: `linear-gradient(to right, rgba(6, 182, 212, 0.95), rgba(6, 182, 212, 0.85))`,
        boxShadow: theme.common.shadows.md,
        backdropFilter: 'blur(5px)'
      }}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          {/* Logo */}
          <Link
            to="/"
            className="flex items-center text-2xl font-bold transition-default hover:opacity-80"
            style={{ color: theme.colors.text.light }}
          >
            <svg
              className="w-8 h-8 mr-2"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 22V12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12V22"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 7V4M12 4H9M12 4H15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 22V19"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            OA Forest Hub
          </Link>

          {/* Desktop navigation */}
          <nav className="hidden md:flex space-x-6 items-center">
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.href}
                className="font-sans transition duration-300 hover:opacity-80"
                style={{ color: theme.colors.text.light }}
              >
                {item.label}
              </Link>
            ))}
            {isAuthenticated ? (
              <Button variant="secondary" size="small" onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <Button
                variant="primary"
                size="small"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            )}
          </nav>

          {/* Mobile menu */}
          <div className="md:hidden">
            <Menu
              title="Menu"
              items={[
                ...menuItems,
                isAuthenticated
                  ? { label: "Logout", onClick: handleLogout }
                  : { label: "Login", href: "/login" },
              ]}
              className="w-40"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
