import React, { useState } from 'react';
import TimelineView from '../components/TimelineComponents/TimelineView';
import ManagementPlanView from '../components/ManagementPlanComponents/ManagementPlanView';
import SearchForm from '../components/BaseComponents/SearchForm';
import SwitchSelector from '../components/BaseComponents/SwitchSelector';
import { FaChartLine, FaCoins } from 'react-icons/fa';

const viewOptions = [
  { value: 'Timeline', label: 'Timeline', icon: FaChartLine },
  { value: 'Annual Review', label: 'Annual Review', icon: FaCoins },
  { value: 'Management Plan', label: 'Management Plan', icon: FaChartLine },
];

export default function ForestInvestmentTimeline() {
  const [portfolioFilter, setPortfolioFilter] = useState('');
  const [activeView, setActiveView] = useState('Timeline');

  const handleSearch = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="fixed top-20 left-0 right-0 z-10 flex justify-between items-center px-4">
        <div className="flex-1" />
        <div className="flex-1 flex justify-center">
          <SwitchSelector
            options={viewOptions}
            activeOption={activeView}
            onSwitch={setActiveView}
            size="small"
          />
        </div>
        <div className="flex-1 flex justify-end">
          <SearchForm
            searchTerm={portfolioFilter}
            onSearchChange={setPortfolioFilter}
            onSubmit={handleSearch}
            placeholder="Search portfolio..."
          />
        </div>
      </div>

      {activeView === 'Timeline' ? (
        <TimelineView searchTerm={portfolioFilter} />
      ) : activeView === 'Management Plan' ? (
        <ManagementPlanView />
      ) : (
        <div className="p-8 text-center text-gray-600">
          Annual Review view coming soon...
        </div>
      )}
    </>
  );
}